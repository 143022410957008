const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start"
      style={{ color: 'white', backgroundColor: '#242323' }}
    >
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4 mb-md-0 text-center">
            <h5 className="text-uppercase">Barcelona</h5>
            <p className="fs-6">
              <b>Teléfono:</b> <a href="tel:+34933182436">93 318 24 36</a> |{' '}
              <a href="tel:+34933182092">93 318 20 92</a>
            </p>
            <p className="fs-6">
              <b>Dirección:</b>
              <a href="https://goo.gl/maps/i6rbqk9rRdVnPox56" target="_blank" rel="noreferrer">
                {' '}
                C/Pelai 42, 2ª, 08001 Barcelona
              </a>
            </p>
            <p className="fs-6">
              <b>Horario:</b> Lunes a Viernes de 8 a 21h | Sábados de 7:30 a 14:30h.
            </p>
          </div>
          <div className="col-lg-4 col-md-12 mb-4 mb-md-0 text-center">
            <h5 className="text-uppercase">Girona</h5>
            <p className="fs-6">
              <b>Teléfono:</b> <a href="tel:+34972655747">972 65 57 47</a>
            </p>
            <p className="fs-6">
              <b>Dirección:</b>{' '}
              <a href="https://goo.gl/maps/wRyngHbEgcvdzpm27" target="_blank" rel="noreferrer">
                C/ Carles Rahola 6-10, 17003 Girona.
              </a>
            </p>
            <p className="fs-6">
              <b>Horario:</b> Lunes a Viernes de 9 a 15h y 15:30 a 17:30h.
            </p>
          </div>
          <div className="col-lg-4 col-md-12 mb-4 mb-md-0 text-center">
            <h5 className="text-uppercase">Sevilla</h5>
            <p className="fs-6">
              <b>Teléfono:</b>
              <a href="tel:+34955147838"> 955 14 78 38</a>{' '}
            </p>
            <p className="fs-6">
              <b>Dirección:</b>{' '}
              <a href="https://goo.gl/maps/kcU3opSs4NZBsE749" target="_blank" rel="noreferrer">
                Av/ San Juan de la Salle 2, 41008 Sevilla
              </a>
            </p>
            <p className="fs-6">
              <b>Horario:</b> Lunes a Viernes de 8 a 20h | Sábados de 7:30 a 14:30h.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
