import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Table,
  Collapse,
  Tooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import * as Icon from 'react-feather';
import ModalLoader from '../../layouts/modal/ModalLoader';
import documentosApiService from '../../services/http/documentosApiService';
import '../../assets/styles/styles.css';

const Ciclo = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [isToolTipHoursOpened, setIsToolTipHoursOpened] = useState(false);
  const [isToolTipResultOpened, setIsToolTipResultOpened] = useState(false);
  const { id, empresaId } = JSON.parse(localStorage.getItem('student'));

  const toggle = (e) => {
    if (e.target.id !== 'boletin') {
      setCollapse(!collapse);
    }
  };
  const year = `${props.ciclo.anioAcademico.substring(0, 2)}/${props.ciclo.anioAcademico.substring(
    2,
    4,
  )}`;

  const downloadFile = async (e) => {
    if (e.stopPropagation) e.stopPropagation();

    if (e.target.id === 'BOLETIN_DE_NOTAS') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobBolnot = await documentosApiService.generarBolnot({
          headquarter: empresaId,
          id,
          cursoId: props.ciclo.codigo,
          idiomaId,
        });

        const blobFinal = blobBolnot.slice(0, blobBolnot.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'JUSTIFICANTE_ORDINARIO') {
      setIsLoading(true);
      try {
        const blobJustificanteOrdinario =
          await documentosApiService.generarJustificanteExamenOrdinario({
            empresa: empresaId,
            alumnoId: id,
          });

        const blobFinal = blobJustificanteOrdinario.slice(
          0,
          blobJustificanteOrdinario.size,
          'application/pdf',
        );
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'JUSTIFICANTE_EXTRAORDINARIO') {
      setIsLoading(true);
      try {
        const blobJustificanteExtraordinario =
          await documentosApiService.generarJustificanteExamenExtraordinario({
            empresa: empresaId,
            alumnoId: id,
          });

        const blobFinal = blobJustificanteExtraordinario.slice(
          0,
          blobJustificanteExtraordinario.size,
          'application/pdf',
        );
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const toggleHours = () => {
    setIsToolTipHoursOpened(!isToolTipHoursOpened);
  };

  const toggleResult = () => {
    setIsToolTipResultOpened(!isToolTipResultOpened);
  };

  return (
    <>
      {isLoading ? <ModalLoader header="Generando el documento" /> : ''}
      <div className="mb-3">
        <div
          className="border-bottom rounded-top mb-0 px-2 py-2"
          style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}
          onClick={(e) => toggle(e)}
        >
          <Row>
            <Icon.Briefcase size={20} className="mt-2 col-1" />
            <h4 className="mt-2 col-6">
              {props.ciclo.nombre} ({year})
            </h4>
            <span className="mt-2 col-2 text-center" id="toolTipHours">
              <Tooltip
                placement="top"
                isOpen={isToolTipHoursOpened}
                target="toolTipHours"
                toggle={toggleHours}
              >
                Horas del ciclo
              </Tooltip>
              <Icon.Clock size={20} className="align-text-top" />
              <b>{props.ciclo.horas}</b>
            </span>
            <span className="mt-2 col-2 text-center" id="toolTipResult">
              {props.ciclo.nota !== '' ? (
                <>
                  <Tooltip
                    placement="top"
                    className="align-middle"
                    isOpen={isToolTipResultOpened}
                    target="toolTipResult"
                    toggle={toggleResult}
                  >
                    Nota final
                  </Tooltip>
                  <Icon.Award size={20} className="align-text-top" />
                  <b>{props.ciclo.nota}</b>
                </>
              ) : (
                ''
              )}
            </span>
            {props.ciclo.documentos.length > 0 && (
              <UncontrolledDropdown group className="col-1 w-auto" id={3} direction="up">
                <DropdownToggle
                  className="bg-transparent border-0 text-dark mr-2"
                  id={1}
                  onClick={(e) => e.stopPropagation && e.stopPropagation()}
                >
                  <Icon.Download size={20} className="align-text-top" id="Descarga" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem id="BOLETIN_DE_NOTAS" onClick={downloadFile} key="BOLETIN_DE_NOTAS">
                    Boletín de notas
                  </DropdownItem>
                  <DropdownItem
                    id="JUSTIFICANTE_ORDINARIO"
                    onClick={downloadFile}
                    key="JUSTIFICANTE_ORDINARIO"
                  >
                    Justificante de examen ordinario
                  </DropdownItem>
                  <DropdownItem
                    id="JUSTIFICANTE_EXTRAORDINARIO"
                    onClick={downloadFile}
                    key="JUSTIFICANTE_EXTRAORDINARIO"
                  >
                    Justificante de examen extraordinario
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Row>
        </div>
        <Collapse isOpen={collapse} className="border px-2 py-2">
          <Table responsive borderless>
            <thead>
              <tr>
                <th className="text-center">Nombre</th>
                <th className="text-center">Matriculado</th>
                <th className="text-center">Horas</th>
                <th className="text-center">Primera convocatoria</th>
                <th className="text-center">Segunda convocatoria</th>
                <th className="text-center">Tercera convocatoria</th>
                <th className="text-center">Cuarta convocatoria</th>
                <th className="text-center">Gracia</th>
                <th className="text-center">Nota</th>
              </tr>
            </thead>
            <tbody>
              {props.ciclo.modulos.map((modulo) => (
                <React.Fragment key={modulo.codigo}>
                  <tr
                    style={{ backgroundColor: '#eef5f9' }}
                    className="border-bottom"
                    key={`${Math.floor(Math.random() * 100)}`}
                  >
                    <td>
                      <span style={{ fontWeight: 'bold' }}>{modulo.nombre}</span>
                    </td>
                    <td
                      style={
                        modulo.completado === false
                          ? { paddingLeft: '50px' }
                          : { paddingLeft: '40px' }
                      }
                    >
                      {modulo.completado === false ? '-' : <Icon.Check size={20} />}
                    </td>
                    <td style={{ paddingLeft: '20px' }}>{modulo.horas}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ paddingLeft: '20px' }}>{modulo.nota}</td>
                  </tr>
                  {modulo.actividades.map((actividad) => (
                    <tr key={actividad.codigo}>
                      <td style={{ paddingLeft: '20px' }}>{actividad.nombre}</td>
                      <td className="text-center">
                        {actividad.matriculada ? <Icon.Check size={20} /> : ''}
                      </td>
                      <td className="text-center">{actividad.horas}</td>
                      <td className="text-center">{actividad.notaConvocatoria1}</td>
                      <td className="text-center">{actividad.notaConvocatoria2}</td>
                      <td className="text-center">{actividad.notaConvocatoria3}</td>
                      <td className="text-center">{actividad.notaConvocatoria4}</td>
                      <td className="text-center">{actividad.gracia}</td>
                      <td className="text-center">{actividad.nota}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapse>
      </div>
    </>
  );
};

Ciclo.propTypes = {
  ciclo: PropTypes.any,
  datos: PropTypes.any,
};

export default Ciclo;
