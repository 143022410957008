const HeadQuartersData = [
  {
    name: 'CEDESCA',
    url: '/images/cedesca.jfif',
  },
  {
    name: 'CNTEC',
    url: '/images/cntec.jfif',
  },
];

export default HeadQuartersData;
