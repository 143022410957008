import { React, useContext, useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import GoogleLogin from 'react-google-login';
import { Col, Row } from 'reactstrap';
import { AuthContext } from '../../context/authContext';
import HeadquarterInput from './HeadquarterInput';
import HeadquartersData from './HeadquartersData';
import ModalSesion from '../../layouts/modal/ModalSession';

const LoginForm = () => {
  const headquarters = HeadquartersData;
  const { loginGoogle, userError, setUserError } = useContext(AuthContext);
  const [empresaId, setEmpresaId] = useState('CEDESCA');
  const clientId = process.env.REACT_APP_CLIENT_ID;

  useEffect(() => {
    gapi.load('auth2', () => {
      gapi.auth2.init({ clientId });
    });
  }, []);

  const responseGoogle = async (response) => {
    const { tokenId, profileObj } = response;
    await loginGoogle({ tokenId, profileObj, empresaId });
  };

  const onChangeRadioValue = (e) => {
    setEmpresaId(e.target.value);
  };

  return (
    <form>
      {userError === true ? (
        <ModalSesion
          isOpen={userError}
          setIsOpen={setUserError}
          body="Usuario no encontrado."
          header="Algo ha salido mal"
        />
      ) : (
        ''
      )}
      <Row>
        {headquarters.map((headquarter) => (
          <Col key={headquarter.name}>
            <HeadquarterInput
              key={headquarter.name}
              headquarter={headquarter.name}
              imgUrl={headquarter.url}
              onChange={onChangeRadioValue}
            />
          </Col>
        ))}
      </Row>
      <Row className="mt-4">
        <GoogleLogin
          className="m-auto w-75"
          theme="dark"
          clientId={clientId}
          buttonText="Iniciar sesión con Google"
          onSuccess={responseGoogle}
          cookiePolicy="single_host_origin"
        />
      </Row>
    </form>
  );
};
export default LoginForm;
