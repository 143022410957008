import HttpService from './httpService';

class AlumnosApiService extends HttpService {
  async buscarAlumnos({ empresaId, email }, { auth }) {
    return this.get('buscarAlumnos', {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      params: {
        empresaId,
        email,
        userEmail: email,
      },
    });
  }

  async cargarAlumno({ id, headquarter, idCedesca, idCntec }) {
    return this.get('cargarAlumno', { params: { id, headquarter, idCedesca, idCntec } });
  }
}

const alumnosApiService = new AlumnosApiService(process.env.REACT_APP_NODE_API);
export default alumnosApiService;
