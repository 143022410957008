import { React, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import loginApiService from '../services/http/loginApiService';
import alumnosApiService from '../services/http/alumnosApiService';

export const AuthContext = createContext('hola');

const initialState = null;

export const ContextProvider = (props) => {
  const [user, setUser] = useState(initialState);
  const [userError, setUserError] = useState(false);

  // Función para hacer el login con Google
  const loginGoogle = async ({ tokenId, profileObj, empresaId }) => {
    try {
      const data = await loginApiService.auth({ tokenId, profileObj });
      const tokenJWT = data?.data?.token;

      // Almacenamos la información del usuario.
      const newUserInformation = { tokenId: tokenJWT, profileObj };

      // Buscamos si el usuario se encuentra en el listado de alumnos.
      const actualData = await alumnosApiService.buscarAlumnos(
        {
          empresaId,
          email: profileObj.email,
        },
        { auth: newUserInformation.tokenId },
      );

      if (actualData.length > 0) {
        const student = {
          id: actualData[0].id,
          empresaId: actualData[0].empresaId,
          email: actualData[0].email,
        };

        localStorage.setItem('student', JSON.stringify(student));
        localStorage.setItem('user', JSON.stringify(newUserInformation));
        setUser(newUserInformation);
      } else {
        setUserError(true);
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const logoutGoogle = () => {
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loginGoogle,
        logoutGoogle,
        userError,
        setUserError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
ContextProvider.propTypes = {
  children: PropTypes.node,
};
