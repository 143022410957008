import PropTypes from 'prop-types';
import ComponentCard from '../../layouts/containers/ComponentCard';
//import Loader from '../../layouts/loader/Loader'
import Ciclo from './Ciclo';

const Ciclos = (props) => {
  return (
    <ComponentCard title="Ciclos formativos matriculados">
      {props.ciclos.map((ciclo) => (
        <Ciclo key={ciclo.codigo} ciclo={ciclo} datos={props.datos} />
      ))}
    </ComponentCard>
  );
};

Ciclos.propTypes = {
  ciclos: PropTypes.any,
  datos: PropTypes.any,
};
export default Ciclos;
