import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import PestañasDatos from './PestañasDatos';
import Matriculaciones from './Matriculaciones';
import Ciclos from './Ciclos';
import alumnosApiService from '../../services/http/alumnosApiService';

const Detalles = () => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id, empresaId } = JSON.parse(localStorage.getItem('student'));
  const [dataApi, setDataApi] = useState('');

  useEffect(() => {
    const fetchAlumnoData = async () => {
      try {
        const data = await alumnosApiService.cargarAlumno({ id, headquarter: empresaId });
        setDataApi(data);
      } catch (error) {
        setDataApi('');
      }
    };

    fetchAlumnoData();
  }, []);

  return (
    <>
      <Col md="12">
        <ComponentCard title="Gestión de usuario">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => {
                  toggle('1');
                }}
              >
                Datos Alumno
              </NavLink>
            </NavItem>
            {dataApi !== '' && dataApi.datos.tutores[0].parentesco === '(Sin Tutores)' ? (
              ''
            ) : (
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Datos tutores
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <PestañasDatos activeTab={activeTab} dataApi={dataApi} />
        </ComponentCard>
        {dataApi !== '' && dataApi.matriculas.length > 0 ? (
          <Matriculaciones matriculas={dataApi.matriculas} />
        ) : (
          ''
        )}

        {dataApi !== '' && dataApi.ciclos.length > 0 ? (
          <Ciclos datos={dataApi.datos} ciclos={dataApi.ciclos} />
        ) : (
          ''
        )}
      </Col>
    </>
  );
};

export default Detalles;
