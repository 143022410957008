import React from 'react';
import { Container, Row, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { CheckCircle } from 'lucide-react';
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';

const SuccessPayment = () => {
  return (
    <>
      <LeftBg className="position-absolute left bottom-0" />
      <RightBg className="position-absolute end-0 top" />
      <Container
        fluid
        className="d-flex justify-content-center align-items-center min-vh-100 position-relative"
      >
        <Row className="justify-content-center">
          <Card className="shadow-lg text-center p-4" style={{ maxWidth: '400px' }}>
            <CardBody>
              <CheckCircle size={64} className="text-success mb-3" />
              <CardTitle tag="h1" className="h4 text-dark mb-2">
                ¡Pago Realizado con Éxito!
              </CardTitle>
              <CardText className="text-muted mb-4">
                Gracias por realizar el pago de tu inscripción. En breve recibirás un correo
                electrónico con tus credenciales de acceso y los detalles de tu matrícula.
              </CardText>
              <p className="text-muted text-center mt-4">
                ¿Tienes alguna pregunta?{' '}
                <a href="mailto:soporte@cedetecgroup.com" className="text-success">
                  Contáctanos
                </a>
              </p>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default SuccessPayment;
