import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './views/login/Login';
import DetallesUsuario from './views/details/DetallesUsuario';
import SuccessPayment from './views/payments/SuccessPayment';
import { AuthContext } from './context/authContext';
import loginApiService from './services/http/loginApiService';

const PrivateRoute = ({ element, isVerified }) => {
  console.log(isVerified, 'sepa');
  return isVerified ? element : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired, // Agregamos la validación
  isVerified: PropTypes.bool.isRequired,
};

const App = () => {
  const { user, logoutGoogle } = useContext(AuthContext); // Usamos el estado `user` para saber si está logueado
  const [isVerified, setIsVerified] = useState(false);
  console.log('hola', isVerified);

  const userSession = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const verificarTokenDeUsuario = async () => {
      try {
        await loginApiService.verifyToken();
        setIsVerified(true);
      } catch (error) {
        setIsVerified(false);
        logoutGoogle();
        localStorage.removeItem('user');
      }
    };

    if (userSession) {
      verificarTokenDeUsuario();
    }
  }, [userSession, logoutGoogle]);

  return (
    <Routes>
      {/* Ruta de successPayment accesible para todos */}
      <Route path="/successPayment" element={<SuccessPayment />} />
      {/* Ruta de login */}
      <Route path="/login" element={user ? <Navigate to="/detalles" /> : <Login />} />

      {/* Ruta privada que redirige a login si no está verificado */}
      <Route
        path="/detalles"
        element={<PrivateRoute isVerified={isVerified} element={<DetallesUsuario />} />}
      />

      {/* Ruta por defecto que redirige dependiendo del estado de autenticación */}
      <Route path="*" element={<Navigate to={user ? '/detalles' : '/login'} />} />
    </Routes>
  );
};

export default App;
