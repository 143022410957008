import { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Table } from 'reactstrap';
import * as Icon from 'react-feather';

const Matricula = (props) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div className="mb-3">
      <div
        className="border-bottom rounded-top mb-0 px-2 py-2"
        onClick={toggle}
        style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}
      >
        <Row>
          <Icon.Book size={20} className="mt-2 col-1" />
          <h4 className="mt-2 col-9">
            {props.matricula.nombre} ({props.matricula.fecha})
          </h4>
        </Row>
      </div>
      <Collapse isOpen={collapse} className="border px-2 py-2">
        <Table responsive borderless>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Código</th>
            </tr>
          </thead>
          <tbody>
            {props.matricula.actividades.map((actividad) => (
              <tr key={actividad.codigo}>
                <td style={{ width: '80%' }}>{actividad.nombre}</td>
                <td style={{ width: '20%' }}>{actividad.codigo}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Collapse>
    </div>
  );
};

Matricula.propTypes = {
  matricula: PropTypes.any,
};

export default Matricula;
