import { TabPane, Row, Col, Input, Form, Label } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

const DatosAlumnos = (props) => {
  return (
    <TabPane tabId="1">
      <Form>
        <Row>
          <Col sm="2" className="mt-1 mt-md-0">
            <Label htmlFor="idalu">
              <small>IDALU</small>
            </Label>
            <Input
              type="text"
              name="idalu"
              id="idalu"
              value={
                props.datosAlumno.datos.escolares.idalu === null
                  ? ''
                  : props.datosAlumno.datos.escolares.idalu
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-0">
            <Label htmlFor="name">
              <small>Nombre</small>
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={
                props.datosAlumno.datos.personales.nombre === null
                  ? ''
                  : props.datosAlumno.datos.personales.nombre
              }
              disabled
            />
          </Col>
          <Col sm="5" className="mt-1 mt-md-0">
            <Label htmlFor="lastname" className="">
              <small>Apellidos</small>
            </Label>
            <Input
              type="text"
              name="apellidos"
              id="lastname"
              value={
                props.datosAlumno.datos.personales.apellidos === null
                  ? ''
                  : props.datosAlumno.datos.personales.apellidos
              }
              disabled
            />
          </Col>
          <Col sm="2" className="mt-1 mt-md-1">
            <Label htmlFor="dni">
              <small>DNI</small>
            </Label>
            <Input
              type="text"
              name="dni"
              id="dni"
              value={
                props.datosAlumno.datos.personales.documento === null
                  ? ''
                  : props.datosAlumno.datos.personales.documento
              }
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm="2" className="mt-1 mt-md-1">
            <Label htmlFor="city">
              <small>Población</small>
            </Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={
                props.datosAlumno.datos.personales.municipio === null
                  ? ''
                  : props.datosAlumno.datos.personales.municipio
              }
              disabled
            />
          </Col>
          <Col sm="2" className="mt-1 mt-md-1">
            <Label htmlFor="born">
              <small>Lugar de nacimiento</small>
            </Label>
            <Input
              type="text"
              name="born"
              id="born"
              value={
                props.datosAlumno.datos.personales.municipioNacimiento === null
                  ? ''
                  : props.datosAlumno.datos.personales.municipioNacimiento
              }
              disabled
            />
          </Col>
          <Col sm="2" className="mt-1 mt-md-1">
            <Label htmlFor="birthday">
              <small>Fecha de nacimiento</small>
            </Label>
            <Input
              type="text"
              name="birthday"
              id="birthday"
              value={
                props.datosAlumno.datos.personales.fechaNacimiento === null
                  ? ''
                  : moment(props.datosAlumno.datos.personales.fechaNacimiento).format('DD/MM/YYYY')
              }
              disabled
            />
          </Col>
          <Col sm="2" className="mt-1 mt-md-1">
            <Label htmlFor="cp">
              <small>Código postal</small>
            </Label>
            <Input
              type="text"
              name="cp"
              id="cp"
              value={
                props.datosAlumno.datos.personales.cp === null
                  ? ''
                  : props.datosAlumno.datos.personales.cp
              }
              disabled
            />
          </Col>
          <Col sm="4" className="mt-1 mt-md-1">
            <Label htmlFor="street">
              <small>Dirección</small>
            </Label>
            <Input
              type="text"
              name="street"
              id="street"
              value={
                props.datosAlumno.datos.personales.domicilio === null
                  ? ''
                  : props.datosAlumno.datos.personales.domicilio
              }
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="email1">
              <small>Email 1</small>
            </Label>
            <Input
              type="text"
              name="email1"
              id="email1"
              value={
                props.datosAlumno.datos.escolares.emailCorp === null
                  ? ''
                  : props.datosAlumno.datos.escolares.emailCorp
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="email2">
              <small>Email 2</small>
            </Label>
            <Input
              type="text"
              name="email2"
              id="email2"
              value={
                props.datosAlumno.datos.personales.emailPers === null
                  ? ''
                  : props.datosAlumno.datos.personales.emailPers
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="phone">
              <small>Teléfono 1</small>
            </Label>
            <Input
              type="text"
              name="phone"
              id="phone"
              value={
                props.datosAlumno.datos.personales.movil === null
                  ? ''
                  : props.datosAlumno.datos.personales.movil
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="phone2">
              <small>Teléfono 2</small>
            </Label>
            <Input
              type="text"
              name="phone2"
              id="phone2"
              value={
                props.datosAlumno.datos.personales.telefono === null
                  ? ''
                  : props.datosAlumno.datos.personales.telefono
              }
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="nss">
              <small>Número seguridad social</small>
            </Label>
            <Input
              type="text"
              name="nss"
              id="nss"
              value={
                props.datosAlumno.datos.personales.nss === null
                  ? ''
                  : props.datosAlumno.datos.personales.nss
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="healthcare">
              <small>Tarjeta sanitaria</small>
            </Label>
            <Input
              type="text"
              name="healthcare"
              id="healthcare"
              value={
                props.datosAlumno.datos.escolares.tsi === null
                  ? ''
                  : props.datosAlumno.datos.escolares.tsi
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="studies">
              <small>Estudios anteriores</small>
            </Label>
            <Input
              type="text"
              name="studies"
              id="studies"
              value={
                props.datosAlumno.datos.escolares.antiguosEstudios === null
                  ? ''
                  : props.datosAlumno.datos.escolares.antiguosEstudios
              }
              disabled
            />
          </Col>
          <Col sm="3" className="mt-1 mt-md-1">
            <Label htmlFor="datestudies">
              <small>Fecha de los últimos estudios</small>
            </Label>
            <Input
              type="text"
              name="datestudies"
              id="datestudies"
              value={
                props.datosAlumno.datos.escolares.fechaUltimoCurso === null
                  ? ''
                  : props.datosAlumno.datos.escolares.fechaUltimoCurso
              }
              disabled
            />
          </Col>
        </Row>
      </Form>
    </TabPane>
  );
};
DatosAlumnos.propTypes = {
  datosAlumno: PropTypes.any,
};
export default DatosAlumnos;
