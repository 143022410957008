import { TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import Tutor from './Tutor';

const DatosTutores = (props) => {
  const arrayTutores = props.datosTutor.datos.tutores;
  return (
    <TabPane tabId="2">
      {arrayTutores.map((tutor) => (
        <Tutor
          key={tutor.documentoTutor}
          nombre={tutor.nombreTutor}
          apellidos={`${tutor.apellido1Tutor} ${tutor.apellido2Tutor}`.trim()}
          telefono={tutor.telefonoTutor}
          dni={tutor.documentoTutor}
          email={tutor.emailTutor}
          parentesco={tutor.parentescoTutor === '(Sin Tutores)' ? '-' : tutor.parentescoTutor}
        />
      ))}
    </TabPane>
  );
};
DatosTutores.propTypes = {
  datosTutor: PropTypes.any,
};
export default DatosTutores;
