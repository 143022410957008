import PropTypes from 'prop-types';
import { Row, Col, Label, Input, Form } from 'reactstrap';

const Tutor = (props) => {
  return (
    <>
      <Form className="mb-3">
        <h3>{props.parentesco}</h3>
        <Row>
          <Col sm="6" className="mt-1 mt-md-0">
            <Label htmlFor="name">
              <small>Nombre</small>
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={props.nombre === null ? '' : props.nombre}
              disabled
            />
          </Col>
          <Col sm="6" className="mt-1 mt-md-0">
            <Label htmlFor="lastname">
              <small>Apellidos</small>
            </Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              value={props.apellidos === null ? '' : props.apellidos}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm="4" className="mt-1 mt-md-0">
            <Label htmlFor="dni" className="">
              <small>DNI</small>
            </Label>
            <Input
              type="text"
              name="dni"
              id="dni"
              value={props.dni === null ? '' : props.dni}
              disabled
            />
          </Col>
          <Col sm="4" className="mt-1 mt-md-1">
            <Label htmlFor="email">
              <small>Email</small>
            </Label>
            <Input
              type="text"
              name="email"
              id="email"
              value={props.email === null ? '' : props.email}
              disabled
            />
          </Col>
          <Col sm="4" className="mt-1 mt-md-1">
            <Label htmlFor="teléfono">
              <small>Teléfono</small>
            </Label>
            <Input
              type="text"
              name="teléfono"
              id="teléfono"
              value={props.telefono === null ? '' : props.telefono}
              disabled
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
Tutor.propTypes = {
  nombre: PropTypes.string,
  apellidos: PropTypes.string,
  dni: PropTypes.string,
  email: PropTypes.string,
  telefono: PropTypes.string,
  parentesco: PropTypes.string,
};
export default Tutor;
