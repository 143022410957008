import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import Header from '../../layouts/header/Header';
import Detalles from '../../components/usuarios/Detalles';
import Footer from '../../components/usuarios/Footer';

const DetallesUsuario = () => {
  const LayoutHorizontal = useSelector((state) => state.customizer.isLayoutHorizontal);

  return (
    <>
      <main>
        <div className="pageWrapper d-md-block d-lg-flex">
          <div className="contentArea">
            <Header />
            <Container fluid className="p-4 boxContainer">
              <div className={LayoutHorizontal ? 'HsidebarFixed' : ''}>
                <Detalles />
              </div>
            </Container>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default DetallesUsuario;
