import HttpService from './httpService';

class LoginApiService extends HttpService {
  async auth({ tokenId, profileObj }) {
    return this.post('auth', { tokenId, profileObj });
  }

  async verifyToken() {
    return this.get('verifyToken');
  }
}

const loginApiService = new LoginApiService(process.env.REACT_APP_NODE_API);
export default loginApiService;
